<template>
  <section>
    <TopBanner title="About Us" :src="require('@/assets/images/banners/banner_aboutus.jpg')" />
    <v-container class="content-container">
      <div id="about">
        <div class="text-center">
          <p class="primary--text text-h5 text-sm-h3 font-weight-bold">
            At New Wave Lending Group
          </p>
          <p class="primary--text text-body-1 text-sm-h5 font-weight-light">
             “innovation” is not just another overused marketing word – it’s our core value and commitment to our partners, our industry, and ourselves.
          </p>
        </div>
        <div class="my-5 text-body-1" style="line-height: 2">
          New Wave Lending Group is a wholesale mortgage lending team unlike any other.  
          We believe in pushing boundaries, exploring possibilities, and building powerful partnerships through modern technology and outside-the-box thinking.  
          With a team of <span class="text-decoration-underline">energetic</span> professionals with decades of combined experience in the industry, New Wave Lending Group is on a mission to revolutionize the wholesale mortgage lending industry by harnessing the power of our diverse loan product portfolio and through our enhanced proprietary technology, intuitive B2B portal interface, and total commitment to delivering results. 
          We believe in a data-driven model centered on delivering superior results, faster turnaround times, and maximum efficiency.  
          We are passionate about providing our partners with a personalized wholesale mortgage experience that is simple to navigate and backed by unsurpassed customer service from start to finish.  
          We are an integrity-first organization dedicated to building lasting partnerships through total transparency and undeniable results.
        </div>
        <div class="my-5 text-body-1" style="line-height: 2">
          Established in 2017 and headquartered in City of Industry, California, New Wave Lending Group provides residential financing solutions to engaged and success-driven mortgage professionals. 
          By embracing an all-inclusive approach to business, our team is able to tackle any situation with agility, efficiency, and effectiveness.  
          We believe every interaction with our partners is an opportunity for growth and success together. 
        </div>
        <div class="my-5 text-body-1" style="line-height: 2">
          At New Wave Lending Group, we don’t believe in waiting for solutions to find us:  We seek solutions.  
          We are committed to continual growth and learning in order to provide our broker partners with the most up-to-date information and knowledge on guidelines, programs, offerings, innovations, and industry best practices.  
          At the end of the day, it is our goal to provide our partners with easy-to-navigate wholesale mortgage solutions that are clear, concise, simple-to-use, and effective.  
        </div>
        <div class="my-5 text-body-1" style="line-height: 2">
          To us, innovation is not just another overused marketing word – it is our core value and commitment to our partners, our industry, and ourselves. 
          We continue to tirelessly pursuit the innovation of better processes, products, and services for our community of valued partners.  
        </div>
        <div class="my-5 text-body-1" style="line-height: 2">
          Embracing <span class="text-decoration-underline">traditional values</span> with a modern-day approach; A new wave of lending is here – Join Us Today!
        </div>
        <div class="my-5 text-body-1" style="line-height: 2">
          Meta Description:
        </div>
        <div class="my-5 text-body-1" style="line-height: 2">
          New Wave Lending Group is a wholesale mortgage lender providing a hybrid approach for both traditional and modern mortgage professionals with our robust B2B solutions, intuitive customer service, maximized efficiency, and competitive rates.
        </div>
      </div>
      <!-- Old Contents -->
      <!-- <div id="about">
        <div class="text-center">
          <p class="primary--text text-h5 text-sm-h3 font-weight-bold">
            Helping you get your Client's Mortgage.
          </p>
          <p class="primary--text text-body-1 text-sm-h5 font-weight-light">
            We are always focused on helping you succeed.
          </p>
        </div>
        <div class="my-8"></div>
        <div class="my-5 text-body-1" style="line-height: 2">
          <span class="font-weight-bold">New Wave Lending Group Inc.</span> 
          was founded with the concept of innovating the mortgage lending industry. 
          Leading the Mortgage lending industry into the next generation with technology, personalized B2B interface, and as always at its core, excellent customer service. 
          By simplifying the workflow process for our mortgage partners, 
          we can deliver a consistent and reliable platform leading to more efficient and faster closings. 
        </div>
        <div class="my-5 text-body-1" style="line-height: 2">
          At our core belief, we treat our mortgage brokers as our partners, delivering excellent customer service together. 
          We look forward to growing together as your <span class="primary--text font-weight-bold">'Partner of Choice'!</span>
        </div>
      </div> -->
    </v-container>
    <Footer />
  </section>
</template>

<script>
import TopBanner from "@/components/TopBanner";
import Footer from "@/views/layouts/Footer";

export default {
  components: {
    TopBanner,
    Footer
  }
}
</script>